import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {apiUrl} from './api-url';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
  TOKEN_KEY = 'token';
  token = localStorage.getItem(this.TOKEN_KEY);

  constructor(private http: HttpClient,
              private router: Router) {
  }

  ServerGet(address: string) {
    const headerWithToken = this.headerWithToken();
    const headers = headerWithToken;
    const options = {headers: headers};
    return this.http.get(address, options)
       .pipe(
         map(res => res),
         catchError((err) => {
           return throwError(err);
         }));
  }

  ServerGetLogin(address: string) {
    const headerWithoutToken = this.headerWithoutToken();
    const headers = headerWithoutToken;
    const options = {headers: headers};
    return this.http.get(address, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return throwError(err);
        }));
  }

  ServerPost(address: string, data: any) {
    const headerWithToken = this.headerWithToken();
    const headers = headerWithToken;
    const options = {headers: headers};
    return this.http.post(address, data, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return throwError(err);
        }));
  }

  ServerPut(address: string, data: any) {
    const headerWithToken = this.headerWithToken();
    const headers = headerWithToken;
    const options = {headers: headers};
    return this.http.put(address, data, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return throwError(err);
        }));
  }

  ServerPostLogin(address: string, data: any) {
    const headerWithoutToken = this.headerWithoutToken();
    const headers = headerWithoutToken;
    const options = {headers: headers};

    return this.http.post(address, data, options)
      .pipe(
        map(res => res),
        catchError((err) => {
          return throwError(err);
        }));
  }

  saveToken(token: string) {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  getLanguage() {
    var lang = localStorage.getItem('language')
    if(lang == 'de'){
      lang = 'de-de';
    }
    else lang= 'en-en';

    return lang;
  }

  headerWithToken() { 
    const token = localStorage.getItem(this.TOKEN_KEY);

    return new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Cache-Control', ' no-cache')
    .set('Content-Type', 'application/json')
    .set('Accept-Language', this.getLanguage())
    .set('Authorization', 'Bearer ' + token);
  }

  headerWithoutToken() {
    return new HttpHeaders()
    .set('Accept', 'application/json')
    .set('Cache-Control', ' no-cache')
    .set('Content-Type', 'application/json')
    .set('Accept-Language', this.getLanguage())
  }
}
