import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from 'src/app/shared/services/service';
import { apiUrl } from 'src/app/shared/services/api-url';
import { DashboardModel } from './dashboard.model';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isVisibleModal = false;
  isSpinner = false;
  capitalTableList: DashboardModel[];
  total: DashboardModel;

  constructor(private modal: NzModalService, private apiService: AuthService, private router:Router, 
              private notification: NotificationService, private translate: TranslatePipe) { 
    this.capitalTableList = [];
    this.total = new DashboardModel();
  }

  async ngOnInit(): Promise<void> {
    await this.getUserInfo();
    this.loadDashboardTableList();
  }

  resetallQuestions(): void {
    this.getUserInfo();
    this.apiService.ServerGet(apiUrl.resetQuestions).subscribe((res => {
      this.notification.sendNotification(this.translate.transform('resetQuestionNotification'), 'success');
      this.loadDashboardTableList();
    }),err => {
      if(err.status == 404){
        this.notification.sendNotification(this.translate.transform('resetNoQuestionNotification'), 'error');
      }
    });
  }

  openModel(): void {
    this.modal.warning({
        nzTitle: this.translate.transform('resetConfirmMessage'),
        nzOkText: this.translate.transform('yes'),
        nzOkType: 'primary',
        nzOnOk: () => this.resetallQuestions(),
        nzCancelText: this.translate.transform('no'),
    });
  }

  cancel(): void {
    this.isVisibleModal = false;
  }

  loadDashboardTableList(): void {
    this.isSpinner = true;
    this.apiService.ServerGet(apiUrl.dashboard).subscribe((res: any) => {
      this.capitalTableList = res.capitals;

      this.total.total_question = res.total_question;
      this.total.blank_question_count = res.total_blank_question;
      this.total.correct_question_count = res.total_correct_question;
      this.total.wrong_question_count = res.total_wrong_question;
      this.isSpinner = false;
    })
  }

  practiceWrong(): void {
    this.getUserInfo();

    if(this.total.wrong_question_count > 0){
      this.router.navigate(['/question', 1], {queryParams: {status: 'wrong' }});
    }
    else {
      this.notification.sendNotification(this.translate.transform('wrongQuestionNotification'), 'info');
    }
  }

  practiceBlank(): void{
    this.getUserInfo();
    if(this.total.blank_question_count > 0){
      this.router.navigate(['/question', 1], {queryParams: {status: 'blank' }});
    }
    else {
      this.notification.sendNotification(this.translate.transform('unansweredQuestionNotification'), 'info');
    }
  }

  async getUserInfo(): Promise<void> {
    await this.apiService.ServerGet(apiUrl.user).toPromise().then(((res:any) => {
      localStorage.setItem('language', res.language);
    }))
  }
}
