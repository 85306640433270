<header class="header">
  <div class="container">
    <div class="logo">
      <img
        [routerLink]="['/capitals']"
        src="/assets/logo/header.png"
        alt="medexam"
        class="logo-desktop"
      />
      <img
        [routerLink]="['/capitals']"
        src="/assets/logo/logo-mobile.png"
        alt="medexam"
        class="logo-mobile"
      />
    </div>
    <div class="menu" *ngIf="!isFirstLogin">
      <a
        [routerLink]="['capitals/']"
        (click)="changeMenu()"
        [ngStyle]="{ color: menuQuestionSelected ? '#009498' : '#000000' }"
        >{{'questions' |translate}}</a
      >
      <div class="line"></div>
      <a
        [routerLink]="['dashboard/']"
        (click)="changeMenu()"
        [ngStyle]="{ color: !menuQuestionSelected ? '#009498' : '#000000' }"
        >{{'dashboard'|translate}}</a
      >
    </div>
    <div class="user-menu">
      <span class="full-name-text">{{ fullName }}</span>
      <div class="logout-wrapper" (click)="logOut()">
        <span class="logout-text">{{'logout' |translate}}</span>
        <i
          nz-icon
          [nzType]="'user-logout'"
          [nzTheme]="'outline'"
        ></i>
      </div>
    </div>

    <div class="hamburger-menu">
      <i (click)="open()" nz-icon nzType="menu" nzTheme="outline"></i>
    </div>
    <div class="drawer-container">
      <nz-drawer
        [nzMaskClosable]="false"
        [nzWidth]="200"
        (nzOnClose)="close()"
        [nzVisible]="isVisible"
      >
        <ng-container *nzDrawerContent>
          <img
            [routerLink]="['/capitals']"
            src="/assets/logo/header.png"
            alt="medexam"
          />
          <div class="drawer-links">
            <div class="drawer-item" *ngIf="!isFirstLogin">
              <a
                [routerLink]="['capitals/']"
                (click)="changeMenu()"
                [ngStyle]="{
                  color: menuQuestionSelected ? '#009498' : '#000000'
                }"
                >{{'questions' |translate}}</a
              >
            </div>
            <div class="drawer-item" *ngIf="!isFirstLogin">
              <a
                [routerLink]="['dashboard/']"
                (click)="changeMenu()"
                [ngStyle]="{
                  color: !menuQuestionSelected ? '#009498' : '#000000'
                }"
                >{{'dashboard' |translate}}</a
              >
            </div>
            <div class="drawer-user-menu drawer-item" (click)="logOut()">
              <span>{{'logout'|translate}}</span>
              <i nz-icon [nzType]="'user-logout'" [nzTheme]="'outline'"></i>
            </div>
          </div>
        </ng-container>
      </nz-drawer>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
