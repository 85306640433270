<div class="login-container">
  <app-spinner *ngIf="isSpinner && !hasSentMail" [isSpinning]="isSpinner"></app-spinner>
  <nz-card class="card-body" *ngIf="!isSpinner">
    <div class="login-wrapper">
      <img class="logo" src="/assets/logo/medexam.png" alt="medexam" />
      <div [ngClass]="{ 'display-none': hasSentMail }">
        <h1>{{'passwordRecovery' |translate}}</h1>
        <span>{{'enterEmail' |translate}}</span>
        <form nz-form [formGroup]="emailForm" (ngSubmit)="submitForm()">
          <nz-form-item class="input-container">
            <nz-form-control [nzErrorTip]="emailErrorTpl">
              <nz-form-label nzRequired for="email">{{'email' |translate}}</nz-form-label>
              <nz-input-group nzPrefixIcon="mail">
                <input [(ngModel)]="email" type="email" nz-input nzRequired formControlName="email"
                  placeholder="mail@exam.com" />
              </nz-input-group>
              <ng-template #emailErrorTpl let-control>
                <ng-container *ngIf="control.hasError('email')">{{'emailValidError' |translate}}</ng-container>
                <ng-container *ngIf="control.hasError('required')">{{'emailRequiredError' |translate}}</ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
          <button class="login-button" nz-button nzType="primary" nzBlock>
            {{'send' |translate}}
          </button>
          <div class="forgot-email-button">
            <a class="forgot-email" (click)="forgotEmail()">{{'forgotEmail' |translate}}</a>
          </div>
          <p *ngIf="isForgotEmail" class="contact-message">
            {{'contactMessage1' |translate}}<b>{{'contactEmail' |translate}}</b>{{'contactMessage2' |translate}}
          </p>
        </form>
      </div>
      <div [ngClass]="{ 'display-none': !hasSentMail }">
        <h1>{{'checkEmail' |translate}}</h1>
        <p>{{'checkEmailMessage' |translate}}</p>
        <button class="login-button" nz-button nzType="primary" nzBlock [routerLink]="['/login']">
          {{'returnLogin' |translate}}
        </button>
      </div>
    </div>
  </nz-card>
</div>