export const LANG_DE_NAME = 'de';

export const LANG_DE_TRANS = {
    login: 'Einloggen',
    selectLanguage: 'Sprache Wählen',
    profileChoose: 'Bitte wählen Sie die Fachrichtung und Ihre Sprache',
    speciality: 'Fachrichtung',
    language: 'Sprache',
    saveAndContinue: 'Speichern und Fortfahren',
    capitalChoose: 'Kapitel auswählen',
    questions: 'FRAGEN',
    question: 'Frage',
    dashboard: 'ÜBERSICHT',
    logout: 'Abmelden',

    //Question buttons
    previous: 'Zurück',
    next: 'Weiter',
    finish: 'Abschliessen',
    checkAnswer: 'Antwort überprüfen',
    source: 'Quelle:',

    //Dashboard
    trackStatictics: 'Verfolgen sie Ihre statistik',
    correct: 'Richtig',
    wrong: 'Falsch',
    unanswered: 'Unbeantwortet',
    capital: 'Kapitel',
    total: 'Gesamt',
    practiceFailed: 'Falsch beantwortete Fragen kreuzen',
    practiceUnanswered: 'Nicht beantwortete Fragen kreuzen',
    resetQuestions: 'Alle Antworten zurücksetzen',
    resetQuestionNotification: 'Ihre Antworten wurden zurückgesetzt.',
    resetNoQuestionNotification: 'Sie haben keine Fragen zum Zurücksetzen',
    yes: 'Ja',
    no: 'Nein',
    resetConfirmMessage: 'Möchten Sie wirklich alle Ihre Antworten löschen?',
    wrongQuestionNotification: 'Sie haben keine falschen Fragen.',
    unansweredQuestionNotification: 'Sie haben keine unbeantworteten Fragen.',
    anyQuestionNotification: 'Sie haben keine Fragen mehr',

    //Login
    emailValidError: 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    emailRequiredError: 'Bitte geben Sie Ihr E-Mail-Adresse ein',
    passwordInputError: 'Bitte geben Sie Ihr Passwort ein',
    password: 'Passwort',
    email: 'E-Mail Adresse',
    forgotPassword: 'Passwort Vergessen',


    //Forgot Password component
    passwordRecovery: 'Passwort-Wiederherstellung',
    enterEmail: 'Bitte geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen ein neues Passwort zu',
    send: 'Senden',
    forgotEmail: 'E-Mail-Adresse vergessen',
    checkEmail: 'Check deine E-Mail-Adresse',
    checkEmailMessage: 'Wir haben Ihnen eine E-Mail gesendet. Bitte überprüfen Sie diese, um Ihr Passwort zurückzusetzen.',
    returnLogin: 'Zurück zur Anmeldung',
    contactMessage1: 'Sie können uns unter ',
    contactEmail: 'support@medexam.app',
    contactMessage2:' kontaktieren, um Ihre registrierte E-Mail-Adresse wiederherzustellen.',
    noSendEmail: 'E-Mail konnte nicht gesendet werden. Bitte versuchen Sie es später erneut.',

    correctPercentage: 'Richtiger Prozentsatz',
};