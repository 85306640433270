<header class="header">
    <div class="container">
        <div class="select-language">
            <span>{{'selectLanguage' |translate}}</span>
        </div>
        <div class="language-button-wrapper">
            <button [ngClass]="currentLanguage=='en' ? 'current-language-button': 'language-button'" (click)="changeLanguage('en')" nz-button nzSize="large" nzType="default">EN</button>
        </div>
        <div class="language-button-wrapper">
            <button [ngClass]="currentLanguage=='de' ? 'current-language-button': 'language-button'" (click)="changeLanguage('de')" nz-button nzSize="large" nzType="default">DE</button>
        </div>
    </div>
</header>
<router-outlet></router-outlet>