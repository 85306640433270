import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/service';
import { apiUrl } from 'src/app/shared/services/api-url';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-capitals',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  hasSentMail: boolean = false;
  email: string;
  emailForm!: FormGroup;
  isForgotEmail:boolean = false;
  isSpinner: boolean = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: AuthService,
    private notification: NotificationService,
    private translate: TranslatePipe

  ) {
    this.email = '';
  }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
    });
  }

  forgotEmail(): void {
    this.isForgotEmail = true;
  }

  submitForm(): void {
    for (const i in this.emailForm.controls) {
      this.emailForm.controls[i].markAsDirty();
      this.emailForm.controls[i].updateValueAndValidity();
    }
    if (this.emailForm.value.email != '') {
      this.resetPassword(this.emailForm.value.email);
    }
  }

  resetPassword(email: string): void {
    var data = {
      email: email,
    };
    this.isSpinner = true;
    this.apiService
      .ServerPostLogin(apiUrl.resetPassword, data)
      .subscribe((res: any) => {
        this.hasSentMail = true;
        this.isSpinner = false;
      },(err: any) => {
        this.notification.sendNotification(this.translate.transform('noSendEmail'), 'error');
        this.isSpinner = false;
      });
  }
}
