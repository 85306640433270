import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginModel } from './services/login.models';
import { AuthService } from '../../shared/services/service';
import { apiUrl } from '../../shared/services/api-url';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginModel: LoginModel;
  passwordVisible = false;
  token: string = '';
  loginForm!: FormGroup;
  loginErrorMessage: string = '';

  constructor(private router: Router, private authService:AuthService, private fb: FormBuilder) {
    this.loginModel = new LoginModel();
   }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  submitForm(): void {
    for (const i in this.loginForm.controls) {
      this.loginForm.controls[i].markAsDirty();
      this.loginForm.controls[i].updateValueAndValidity();
    }
    if(this.loginModel.email != '' && this.loginModel.password != ''){
      this.login();
    }

  }

  login(): void {
    this.authService.ServerPostLogin(apiUrl.login, this.loginModel).subscribe((res : any) => {
      this.token = res['access'];
      sessionStorage.setItem('is_first_login', res['is_first_login']);
      sessionStorage.setItem('speciality', res['speciality'] ? res['speciality'] : '');
      sessionStorage.setItem('full_name', res['full_name']);
      localStorage.setItem('language', res['language']);

      if (this.token) {
          this.authService.saveToken(this.token);

          if(res['is_first_login']){
            this.router.navigate(['/profile']);
          }
          else {
            this.router.navigate(['/capitals']);
          }
        }
      },(err) => {
        this.loginErrorMessage = err.error.message.slice(err.error.message.indexOf(":")+1);
      }
      
    );  
  }

  resetPassword(): void {
    this.router.navigate(['/reset-password']);
  }
}
