export class QuestionModel {
    // tslint:disable-next-line:variable-name
    id: number;
    capital: number;
    question_text: string;
    question_source_name: string;
    question_source_link: string;
    option: OptionModel[];
    user_answer_id: number | null;
    question_status: boolean | null;
    next_question: string | null;
    previous_question: string | null;
    is_correct: boolean;
    image: string;
    constructor() {
      this.question_text = '';
      this.question_source_name = '',
      this.question_source_link = '',
      this.capital = 0;
      this.next_question = null;
      this.previous_question = null;
      this.id = 0;
      this.question_status = null;
      this.option = [];
      this.user_answer_id = null;
      this.is_correct = false;
      this.image = '';
    }
}

export class OptionModel {
  option_text: string | null;

  constructor() {
    this.option_text = ''

  }
}

export class UserAnswerModel {
  option_hint: string;
  user_check: boolean;
  option_is_correct: boolean;

  constructor() {
    this.option_hint = '';
    this.user_check = false;
    this.option_is_correct = false;
  }
}

export class UserAnswerStogare {
  question: number;
  option_1: boolean;
  option_2: boolean;
  option_3: boolean;
  option_4: boolean;
  option_5: boolean;

  constructor() {
    this.question = 0;
    this.option_1 = false;
    this.option_2 = false;
    this.option_3 = false;
    this.option_4 = false;
    this.option_5 = false;
  }
}