export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
    login: 'Log in',
    selectLanguage: 'Select Language',
    profileChoose: 'Please choose your speciality and language',
    speciality: 'Speciality',
    language: 'Language',
    saveAndContinue: 'Save and Continue',
    capitalChoose: 'You can choose a capital here.',
    questions: 'QUESTIONS',
    question: 'Question',
    dashboard: 'OVERVIEW',
    logout: 'Logout',

    //Question buttons
    previous: 'Previous',
    next: 'Next',
    finish: 'Finish',
    checkAnswer: 'Check Answer',
    source: 'Source:',

    //Dashboard
    trackStatictics: 'Track your statictics',
    correct: 'Correct',
    wrong: 'Wrong',
    unanswered: 'Unanswered',
    capital: 'Capital',
    total: 'Total',
    practiceFailed: 'Practice failed questions',
    practiceUnanswered: 'Practice unanswered questions',
    resetQuestions: 'Reset all questions',
    resetQuestionNotification: 'Your answers have been reset.',
    resetNoQuestionNotification: 'You have no questions to reset',
    yes: 'Yes',
    no: 'No',
    resetConfirmMessage: 'Are you sure you want to delete all your answers?',
    wrongQuestionNotification: 'You do not have any wrong questions.',
    unansweredQuestionNotification: 'You do not have any unanswered questions.',
    anyQuestionNotification: 'You do not have any questions.',


    //Login
    emailValidError: 'Please input valid email adress',
    emailRequiredError: 'Please input your email adress',
    passwordInputError: 'Please input your password',
    password: 'Password',
    email: 'Email',
    forgotPassword: 'Forgot Password',
    passwordRecovery: 'Password Recovery',
    enterEmail: 'Please enter your e-mail and we’ll send you a new password',
    send: 'Send',
    forgotEmail: 'Forgot Email',
    checkEmail: 'Check your email',
    checkEmailMessage: 'We sent you an email, please check it to reset your password.',
    returnLogin: 'Return to login',
    contactMessage1: 'You can contact us at ',
    contactEmail: 'support@medexam.app',
    contactMessage2:' to recover your registered email.',
    noSendEmail: 'Email could not be sent. Please try again later.',

    correctPercentage: 'Correct percentage',
};