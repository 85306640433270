import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {AccountService} from './account.service';
	

@Injectable()
export class AuthGuard implements CanActivate {
	

	constructor(private accountService: AccountService, private router: Router) {}

	async canActivate() {
	  let logged = await this.accountService.isLoggedIn();

      if(logged){
          return true;
      }

      this.router.navigate(['/login']);
      return false;
	}
}