import {Injectable} from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationPosition = 'topRight'; // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter, center
  constructor(private notification: NzNotificationService) {
  }

  sendNotification(message: string, type: string) {
    this.notification.create(
        type,
        '',
        message,
        {
          nzClass: 'notification-text',
          nzDuration: 12000,
        },

      );
  }
}
