<app-spinner *ngIf="isSpinner" [isSpinning]="isSpinner"></app-spinner>
<div class="dashboard-container" *ngIf="!isSpinner">
  <nz-card class="card-body">
    <div class="dashboard-wrapper">
      <span>{{'dashboard' |translate}}</span>
      <nz-table nzTemplateMode>
          <thead>
            <tr>
              <th></th>
              <th>{{'questions' |translate |titlecase}}</th>
              <th>{{'correct' |translate}}</th>
              <th>{{'wrong' |translate}}</th>
              <th>{{'unanswered' |translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of capitalTableList; let i = index">
              <td><b>{{i+1}}.</b> {{data.name}}</td>
              <td>{{data.total_question}}</td>
              <td>{{data.correct_question_count}}</td>
              <td>{{data.wrong_question_count}}</td>
              <td>{{data.blank_question_count}}</td>
            </tr>
            <tr class="total-row">
                <td>{{'total' |translate}}</td>
                <td>{{total.total_question}}</td>
                <td>{{total.correct_question_count}}</td>
                <td>{{total.wrong_question_count}}</td>
                <td>{{total.blank_question_count}}</td>
            </tr>
          </tbody>
      </nz-table>
      <div class="button-group">
        <div>
          <button (click)="practiceWrong()" nz-button nzType="primary" nzSize="large" class="first-button">{{'practiceFailed' |translate}}</button>
          <button (click)="practiceBlank()" nz-button nzType="primary" nzSize="large">{{'practiceUnanswered' |translate}}</button>
        </div>
      </div>
      <div class="reset-questions">
        <a (click)="openModel()">{{'resetQuestions' |translate}}</a>
      </div>
    </div>
  </nz-card>
</div>

