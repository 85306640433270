import {Inject, Injectable} from '@angular/core';
import {TRANSLATIONS} from './translation';

@Injectable()
export class TranslateService{

    private _currentLang: string;
    
    constructor(@Inject(TRANSLATIONS) private _translations: any) {
        this._currentLang = 'en';
    }

    public get currentLang() {

      var lang = localStorage.getItem('language');
      if(lang == 'de'){
          this._currentLang = lang;
      }
      else this._currentLang = 'en';

      return this._currentLang;
    }

    public instant(key: string) {
      // call translation
      return this.translate(key);
    }

    private translate(key: string): string {
      // private perform translation
      let translation = key;

      if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
        return this._translations[this.currentLang][key];
      }

      return translation;
    }
}