import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {AccountService} from './account.service';
	

@Injectable()
export class ComponentGuard implements CanActivate {
	

	constructor(private accountService: AccountService, private router: Router) {}

	async canActivate() {
	  let firstLogin = await this.accountService.isFirstLogin();

      if(!firstLogin){
          return true;
      }

      this.router.navigate(['/profile']);
      return false;
	}
}