<app-spinner *ngIf="isSpinner" [isSpinning]="isSpinner"></app-spinner>
<div class="breadcrumb-container" *ngIf="specialityName && !isSpinner">
  <nz-breadcrumb>
    <nz-breadcrumb-item>
      {{'questions'|translate |titlecase}}
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="['/capitals']">{{specialityName}}</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <span class="last-breadcrumb">{{capital.index}}.&nbsp;{{capital.name}}</span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>
<div class="questions-container" *ngIf="capital.name && !isSpinner">
  <nz-card class="card-body">
    <div class="questions-wrapper" *ngIf="!isSpinner">
      <div class="questions-list">
        <h2>{{capital.index}}. {{capital.name}}</h2>
        <div class="correct-percentage">
          <span>{{'correctPercentage' |translate}}: {{correctPercentage}}</span>
        </div>
        <nz-list nzSize="small" *ngFor="let question of questionList; let i = index">
          <nz-list-item (click)="loadQuestion(i, question)">
            <div *ngIf="question.question_status!= null" class="answered-line"
              [ngClass]="question.question_status ? 'line-green' : 'line-red'"></div>
            <div *ngIf="question.question_status == null" class="answered-line"></div>
            <span>{{'question' |translate}} {{i+1}}</span>
          </nz-list-item>
        </nz-list>
      </div>
    </div>
  </nz-card>
</div>
