export class ProfileModel {
    // tslint:disable-next-line:variable-name
    id: number;
    name: string;
    is_selected_speciality: boolean;
  
    constructor() {
      this.id = 0;
      this.name = '';
      this.is_selected_speciality = false;
    }
}

export class UserModel {
  speciality: number;
  language: string | null;

  constructor(){
    this.speciality = 0;
    this.language = 'EN';
  }
}