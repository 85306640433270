import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CapitalModel } from './models/capital.model';
import { apiUrl } from 'src/app/shared/services/api-url';
import { AuthService } from 'src/app/shared/services/service';
@Component({
  selector: 'app-capitals',
  templateUrl: './capitals.component.html',
  styleUrls: ['./capitals.component.scss']
})
export class CapitalsComponent implements OnInit {

  capitals: CapitalModel[] = [];
  specialityName = '';

  constructor(private router: Router, private apiService: AuthService) { }

  async ngOnInit(): Promise<void> {
    await this.apiService.ServerGet(apiUrl.user).toPromise().then(((res:any) => {
      this.specialityName = res.speciality_name;
      localStorage.setItem('language', res.language);
      this.getCapitals();
    }))
  }

  getCapitals(): void {
    this.apiService.ServerGet(apiUrl.capitals).subscribe((res: any) => {
        this.capitals = res;
      }
    );
  }

  goQuestions(capital: CapitalModel, i:any): void {
    sessionStorage.setItem('currentCapitalIndex', i+1);
    this.router.navigate(['/questions'], {queryParams: {capital: capital.id}});
  }
}

