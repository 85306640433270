import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent implements OnInit {

  currentLanguage: string;
  constructor() {
    const lang = localStorage.getItem('language');
    if(lang == 'de'){
      this.currentLanguage = 'de';
    }
    else{
      this.currentLanguage = 'en';
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges() {   
  }

  changeLanguage(lang: string){
    this.currentLanguage = lang;
    localStorage.setItem('language', lang);
  }
}
