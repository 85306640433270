<div class="profile-container">
  <nz-card class="card-body">
    <div class="profile-wrapper">
      <h1>{{'profileChoose' |translate}}</h1>
      <div>
        <div [ngClass] = "profileModel.length >= 3 ? 'speciality-container speciality-wrapper': 'speciality-container'">
          <span class="speciality-languange-wrapper">{{'speciality' |translate}}</span>
          <ul nz-menu nzMode="inline">
            <li *ngIf="userSpeciality.name" nz-submenu nzTitle="{{userSpeciality.name}}" nzIcon="aim" nzOpen
              class="selected-border">
              <ul *ngFor="let speciality of profileModel">
                <li *ngIf="speciality" (click)="changeSpeciality(speciality)" nz-menu-item
                  [nzSelected]="speciality.is_selected_speciality">{{ speciality.name }}</li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="languange-container">
          <span class="speciality-languange-wrapper">{{'language' |translate}}</span>
          <ul nz-menu nzMode="inline">
            <li *ngIf="userModel.language" nz-submenu nzTitle="{{userModel.language === 'en' ? 'English': 'Deutsch'}}"
              nzIcon="flag" nzOpen>
              <ul>
                <li (click)="changeLanguage('de')" nz-menu-item [nzSelected]="userModel.language === 'de'">Deutsch</li>
                <li (click)="changeLanguage('en')" nz-menu-item [nzSelected]="userModel.language === 'en'">English</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="save-button">
        <button nz-button nzType="primary" nzSize="large" (click)="saveProfile()">
          {{'saveAndContinue' |translate}}
        </button>
      </div>
    </div>
  </nz-card>
</div>