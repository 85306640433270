import {environment} from '../../../environments/environment';

let apiAddress = environment.apiURL;

export const apiUrl = {
  login: apiAddress + '/token/',
  passwordReset: apiAddress + '/token/passwordReset/',
  tokenVerify: apiAddress + '/token/verify/',
  resetQuestions: apiAddress + '/answers/reset/',
  specialities: apiAddress + '/specialities/',
  user: apiAddress + '/users/me/',
  capitals: apiAddress + '/capitals/',
  questions: apiAddress + '/questions/',
  blankQuestions: apiAddress + '/questions/blank/',
  wrongQuestions: apiAddress + '/questions/wrong/',
  answers: apiAddress + '/answers/',
  resetPassword: apiAddress + '/user/reset-password/',
  dashboard: apiAddress + '/dashboard/',
  profileVerify: apiAddress + '/users/verify/',
  correctPercentage: apiAddress + '/correct-percentage/',
};