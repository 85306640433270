import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { NzCardModule } from 'ng-zorro-antd/card/';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzModalService, NzModalModule } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzNotificationModule, NzNotificationService } from 'ng-zorro-antd/notification';
import { CapitalsComponent } from './components/capitals/capitals.component';
import { QuestionListComponent } from './components/capitals/question-list/question-list.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { QuestionDetailComponent } from './components/capitals/question-detail/question-detail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from './shared/guard/account/auth.guard';
import { NotificationService } from './shared/services/notification/notification.service';
import { SpinnerModule } from './shared/components/spinner-component/spinner.module';
import { TranslateModule } from './shared/pipes/translate.module';
import { TranslatePipe } from './shared/pipes/translate.pipe';
import { TranslateService } from './shared/services/translate/translate.service';
import { TRANSLATION_PROVIDERS } from './shared/services/translate/translation';
import { ProfileGuard } from './shared/guard/account/profile.guard';
import { ComponentGuard } from './shared/guard/account/component.guard';
import { ChangeLanguageComponent } from './shared/components/change-language/change-language.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CapitalsComponent,
    QuestionListComponent,
    HeaderComponent,
    ChangeLanguageComponent,
    ProfileComponent,
    DashboardComponent,
    QuestionDetailComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //antd 
    NzCardModule,
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzListModule,
    NzBreadCrumbModule,
    NzMenuModule,
    NzTableModule,
    NzFormModule,
    NzModalModule,
    NzDrawerModule,
    NzNotificationModule,
    NzSpinModule,

    //custom module
    SpinnerModule,
    TranslateModule,

  ],
  providers: [
    AuthGuard, 
    ProfileGuard, 
    ComponentGuard, 
    NzModalService, 
    NzNotificationService, 
    NotificationService, 
    TranslatePipe, 
    TranslateService, 
    TRANSLATION_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
