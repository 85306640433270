import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Input } from '@angular/core';
import { AccountService } from '../../guard/account/account.service';
import { AuthService } from '../../services/service';
import { apiUrl } from '../../services/api-url';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private router: Router, private accountService: AccountService, private apiService: AuthService) {}
  menuQuestionSelected = true;
  isVisible = false;
  isFirstLogin = false;
  fullName = '';

  ngOnInit(): void {
    var name = sessionStorage.getItem('full_name');
    if (name) {
      this.fullName = name;
    }

    if(this.router.url == '/profile'){
      this.isFirstLogin = true;
    }

    if(this.router.url == '/dashboard'){
      this.menuQuestionSelected =  false;
    }

    this.router.events.subscribe((val) => {
      if(val instanceof NavigationEnd) {
        if(this.router.url == '/dashboard'){
          this.isFirstLogin = false;
          this.menuQuestionSelected = false;
        }
        else if(this.router.url == '/profile'){
          this.isFirstLogin = true;
        }
        else {
          this.isFirstLogin = false;
          this.menuQuestionSelected = true;
        }
      }
    });
  }

  logOut(): void {
    localStorage.removeItem('token');
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  changeMenu(): void {
    this.menuQuestionSelected = !this.menuQuestionSelected;
    this.isVisible = false;
  }

  close(): void {
    this.isVisible = false;
  }

  open(): void {
    this.isVisible = true;
  }
}
