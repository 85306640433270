import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { apiUrl } from '../../services/api-url';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor( private router: Router, private apiService: AuthService, private http: HttpClient) {}

  tokenVerify = false;
  firstLogin = true;

  headerWithoutToken = new HttpHeaders()
  .set('Accept', 'application/json')
  .set('Cache-Control', ' no-cache')
  .set('Content-Type', 'application/json')

  async isLoggedIn(): Promise<boolean> {
    const token = this.apiService.getToken();
      if (token) {

        await this.verifyToken().toPromise().then(() => {
          this.tokenVerify = true;
        });

        if(this.tokenVerify) return true;
        else return false

      } else {
        this.router.navigate(['/login']);
        return false;
      }
    
  }
  verifyToken() {
    const token = this.apiService.getToken();
    const headers = this.headerWithoutToken;
    const options = {headers: headers};
    return this.http.post(apiUrl.tokenVerify, {token:token}, options)
        .pipe(
          map(res => res),
          catchError((err) => {
            return throwError(err);
          }));
  }

  async isFirstLogin(): Promise<boolean>  {
    await this.apiService.ServerGet(apiUrl.profileVerify).toPromise().then((res:any) => {
      this.firstLogin = res.is_first_login;
    })
    if(this.firstLogin) return true;
    else return false;
  }
}