import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CapitalModel } from '../models/capital.model';
import { QuestionModel } from '../models/question.model';
import { AuthService } from 'src/app/shared/services/service';
import { apiUrl } from 'src/app/shared/services/api-url';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';
@Component({
  selector: 'app-question-list',
  templateUrl: './question-list.component.html',
  styleUrls: ['./question-list.component.scss']
})
export class QuestionListComponent implements OnInit {
  capital: CapitalModel;
  currentCapitalIndex = 1;
  questionList: QuestionModel[] = [];
  noCapitalMessage = '';
  specialityName = '';
  isSpinner = false;
  correctPercentage = 0;
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService,
              private notification: NotificationService, private translate: TranslatePipe) {
    this.capital = new CapitalModel;
    this.activatedRoute.queryParams.subscribe(data => {
      this.capital.id = data.capital;
    })
    let capitalIndex = sessionStorage.getItem('currentCapitalIndex');
    
    if(capitalIndex){
      this.currentCapitalIndex = +capitalIndex;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.authService.ServerGet(apiUrl.user).toPromise().then(((res:any) => {
      this.specialityName = res.speciality_name;
      localStorage.setItem('language', res.language);
      this.loadQuestions();
      this.getCorrectPercentage();
    }))
  }

  loadQuestions(): void {
    const queryParams = '?capital_id='+this.capital.id
    this.isSpinner = true;
    this.authService.ServerGet(apiUrl.questions + queryParams).subscribe((res: any) => {
      this.isSpinner = false;
      if(res.length > 0) {
        this.questionList = res;
        this.capital.name = res[0].capital.name;
        this.capital.index = res[0].capital.capital_index;
      }
      else {
        this.notification.sendNotification(this.translate.transform('anyQuestionNotification'), 'info');
        this.router.navigate(['/capitals']);
      }
    },err => {
      this.notification.sendNotification(this.translate.transform('anyQuestionNotification'), 'info');
      this.router.navigate(['/capitals']);
    })
  }

  loadQuestion(index:number, question: QuestionModel): void {
    localStorage.setItem('questionId', (question.id).toString())
    this.router.navigate(['/question', (index+1)], {queryParams: {capital: this.capital.id }});
  }


  getCorrectPercentage(): void {
    const queryParams = '?capital_id='+this.capital.id
    this.authService.ServerGet(apiUrl.correctPercentage + queryParams).subscribe((res:any) => {
      this.correctPercentage = res.correct_percentage;
    })
  }
}
