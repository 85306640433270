<div class="capital-container">
  <nz-card class="card-body" *ngIf="capitals.length > 0">
    <div class="capital-wrapper">
      <h2 *ngIf="specialityName">{{specialityName}}</h2>
      <span>{{'capitalChoose' |translate}}</span>
      <nz-list nzSize="small" class="item-list">
        <nz-list-item *ngFor="let capital of capitals; let i=index" (click)="goQuestions(capital, i)">
          <a>
            {{ i + 1 }}. <span class="item-name">{{ capital.name }}</span>
          </a>
        </nz-list-item>
      </nz-list>
    </div>
  </nz-card>
</div>
