export class CapitalModel {
    // tslint:disable-next-line:variable-name
    id: number;
    index: number;
    name: string;

    constructor() {
      this.name = '';
      this.id = 0;
      this.index = 0;
    }
}
