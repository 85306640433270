import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/service';
import { CapitalModel } from '../models/capital.model';
import { QuestionModel, UserAnswerModel } from '../models/question.model';
import { apiUrl } from 'src/app/shared/services/api-url';
import { NotificationService } from 'src/app/shared/services/notification/notification.service';
import { TranslatePipe } from 'src/app/shared/pipes/translate.pipe';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss']
})
export class QuestionDetailComponent implements OnInit {
  checkAnswer = false;
  checkAnswerStore = false;
  options = ["A","B","C","D","E"];
  isSpinner = false;

  question: QuestionModel;
  capital: CapitalModel;
  specialityName = '';
  userAnswer: UserAnswerModel[];
  userAnswerStore: UserAnswerModel[];
  questionIndex = 1;
  questionId = 1;
  questionStatus: string = '';
  requestUrl: string = '';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private authService: AuthService, 
              private notification: NotificationService, private translate: TranslatePipe) { 

    this.question = new QuestionModel;
    this.capital = new CapitalModel;
    this.userAnswer = [];
    this.userAnswerStore = [];
    
    const id = this.activatedRoute.snapshot.paramMap.get('id');

    if(id){
      this.questionIndex = +id;
    }

    this.activatedRoute.queryParams.subscribe(data => {
      this.capital.id = data.capital;
      this.questionStatus = data.status;
    })

    let questionId = localStorage.getItem('questionId');
    if(questionId){
      this.questionId = +questionId;
    }
  }

  async ngOnInit(): Promise<void> {
    if(this.questionStatus == 'wrong'){
      this.requestUrl = apiUrl.wrongQuestions;
    }
    else if(this.questionStatus == 'blank'){
      this.requestUrl = apiUrl.blankQuestions;
    }
    else {
      this.requestUrl = apiUrl.questions + this.questionId + '/?capital_id=' + this.capital.id;
    }

    await this.authService.ServerGet(apiUrl.user).toPromise().then(((res:any) => {
      this.specialityName = res.speciality_name;
      localStorage.setItem('language', res.language);
      this.resetUserAnswer();
      this.loadQuestion();
    }))
  }


  sendAnswer(): void {
    this.checkAnswer = true;
    this.checkAnswerStore = true;
    this.sendQuestionAnswer();
  }

  statusControl(): boolean {
    for(var i=0; i< this.userAnswer.length; i++){
      if(this.userAnswer[i].user_check != this.userAnswerStore[i].user_check){
        return true;
      }
    }
    if(this.checkAnswerStore) return true;
    return false;
  }
  async sendQuestionAnswer(): Promise<void> {
    if(this.statusControl()){
      if(this.question.user_answer_id){
       await this.updateQuestionAnswer();
      }
      else {
       await this.addQuestionAnswer();
      }
    }
    this.checkAnswerStore = false;
  }

  async addQuestionAnswer(): Promise<void> {
    var data = {
      "question": this.question.id,
      "is_checked": this.checkAnswer,
      "is_user_check_1": this.userAnswer[0].user_check,
      "is_user_check_2": this.userAnswer[1].user_check,
      "is_user_check_3": this.userAnswer[2].user_check,
      "is_user_check_4": this.userAnswer[3].user_check,
      "is_user_check_5": this.userAnswer[4].user_check,
    }

  await  this.authService.ServerPost(apiUrl.answers, data).toPromise().then((res:any) => {
      this.question.is_correct = res.is_correct;
      if(this.checkAnswer) {
        this.loadUserAnswer(res, this.userAnswer);
        this.loadUserAnswer(res, this.userAnswerStore);
      }

    })
  }

  async updateQuestionAnswer(): Promise<void> {
    var data = {
      "is_checked": this.checkAnswer,
      "is_user_check_1": this.userAnswer[0].user_check,
      "is_user_check_2": this.userAnswer[1].user_check,
      "is_user_check_3": this.userAnswer[2].user_check,
      "is_user_check_4": this.userAnswer[3].user_check,
      "is_user_check_5": this.userAnswer[4].user_check,
    }

  await  this.authService.ServerPut(apiUrl.answers+this.question.user_answer_id+'/', data).toPromise().then((res: any) => {
      this.question.is_correct = res.is_correct;
      if(this.checkAnswer) {
        this.loadUserAnswer(res, this.userAnswer);
        this.loadUserAnswer(res, this.userAnswerStore);
      }
      
      })
  }

  checkOption(id: number): void {
    this.userAnswer[id].user_check = !this.userAnswer[id].user_check;
    if(this.checkAnswer){
      this.checkAnswer = false;
      this.checkAnswerStore = false;
    }
  }

  loadQuestion(): void {
    this.isSpinner = true;
    this.authService.ServerGet(this.requestUrl).subscribe((res: any) => {
      this.loadData(res);
      this.isSpinner = false;
    }, 
    (err: Error) => {
      this.sendNotification();
      this.isSpinner = false;
    })
  }

  async nextQuestion(): Promise<void> {
    this.isSpinner = true;
    await this.sendQuestionAnswer();
    let isAnswered = this.statusControl();
    let isCorrect = this.question.is_correct;
    this.resetUserAnswer();
    if(this.question.next_question){

      if(this.questionStatus == 'wrong'){
        this.requestUrl = apiUrl.wrongQuestions + this.question.next_question + '/';
        if(isCorrect) this.questionIndex -=1;
      }
      else if(this.questionStatus == 'blank'){
        this.requestUrl = apiUrl.blankQuestions + this.question.next_question + '/';
        if(isAnswered) this.questionIndex -= 1;
      }
      else {
        this.requestUrl = apiUrl.questions + this.question.next_question + '/?capital_id='+this.capital.id;

      }
     await this.authService.ServerGet(this.requestUrl).toPromise().then((res: any) => {
        this.loadData(res);
        this.questionIndex += 1;
        this.isSpinner = false;
      })
    }
  }

  async prevQuestion(): Promise<void> {
    this.isSpinner = true;
    await this.sendQuestionAnswer();
    this.resetUserAnswer();
    if(this.question.previous_question){
      
      if(this.questionStatus == 'wrong'){
        this.requestUrl = apiUrl.wrongQuestions + this.question.previous_question + '/';
      }
      else if(this.questionStatus == 'blank'){
        this.requestUrl = apiUrl.blankQuestions + this.question.previous_question + '/';
      }
      else {
        this.requestUrl = apiUrl.questions + this.question.previous_question + '/?capital_id='+this.capital.id;

      }

      await this.authService.ServerGet(this.requestUrl).toPromise().then((res: any) => {
        this.loadData(res);
        this.questionIndex -= 1;     
        this.isSpinner = false;
      })
    }
  }

  loadData(res: any): void {
    this.checkAnswer = false;
    let data = res;
    if(data != undefined || data != null) {
      this.capital.name = data.capital.name;
      this.capital.id = data.capital.id;
      this.capital.index = data.capital.capital_index;
      this.question = data;
      this.question.next_question = res.next_prev_question.next_question;
      this.question.previous_question = res.next_prev_question.prev_question;
  
      let user_answer_array = data.user_answer
      if(user_answer_array != null) {
        this.loadUserAnswer(user_answer_array, this.userAnswer);
        this.loadUserAnswer(user_answer_array, this.userAnswerStore);
        this.checkAnswer = user_answer_array.is_checked;
      }
      this.loadOptions(data)
    }
    else {
      this.sendNotification();
    }

    if(this.questionStatus){
      this.checkAnswer = false;
    }
  }

  loadUserAnswer(data: any, array: UserAnswerModel[]): void {
    array[0] =  {'option_hint': data.option_1.option_hint, 'user_check': data.option_1.user_check, 
    'option_is_correct': data.option_1.option_is_correct }

    array[1] = {'option_hint': data.option_2.option_hint, 'user_check': data.option_2.user_check, 
        'option_is_correct': data.option_2.option_is_correct }      

    array[2] = {'option_hint': data.option_3.option_hint, 'user_check': data.option_3.user_check, 
        'option_is_correct': data.option_3.option_is_correct }    

    array[3] = {'option_hint': data.option_4.option_hint, 'user_check': data.option_4.user_check, 
        'option_is_correct': data.option_4.option_is_correct }

    array[4] = {'option_hint': data.option_5.option_hint, 'user_check': data.option_5.user_check, 
        'option_is_correct': data.option_5.option_is_correct }
    
    this.question.user_answer_id = data.id;

  }

  loadOptions(data: any): void {
    let options = [
      data.option_1_text,
      data.option_2_text,
      data.option_3_text,
      data.option_4_text,
      data.option_5_text
    ]

    this.question.option = options;
  }

  resetUserAnswer(): void {
    this.userAnswerStore = new Array(5);
    this.userAnswer = new Array(5);
    for(var i=0; i<5; i++){
      this.userAnswer[i] = {user_check: false, option_hint: '', option_is_correct: false};
      this.userAnswerStore[i] = {user_check: false, option_hint: '', option_is_correct: false};
    }
  }

  goDashboard(): void {
    this.router.navigate(['/questions'], {queryParams: {capital: this.capital.id}});
  }

  sendNotification(): void {
    if(this.questionStatus == 'wrong'){
      this.notification.sendNotification(this.translate.transform('wrongQuestionNotification'), 'info');
      this.router.navigate(['/dashboard']);

    }
    else if (this.questionStatus == 'blank'){
      this.notification.sendNotification(this.translate.transform('unansweredQuestionNotification'), 'info');
      this.router.navigate(['/dashboard']);

    }
    else {
      this.notification.sendNotification(this.translate.transform('anyQuestionNotification'), 'info');
      this.router.navigate(['/capitals']);
    }
  }

  optionCssClass(id: number): string {

    if(this.checkAnswer){
      if(this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check){
        return "option-letter-circle option-correct";
      }
      else if(!this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check){
        return "option-letter-circle option-wrong";
      }
      else if(this.userAnswer[id].option_is_correct && !this.userAnswer[id].user_check){
        return "option-letter-circle option-no-check-correct"
      }
      else {
        return "option-letter-circle option-no-check-wrong"
      }
    }
    
    else if(this.userAnswer[id].user_check){
      return "option-letter-circle option-checked"
    }
    return "option-letter-circle"
  }

  explanationCssClass(id: number): string {
    if(this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check) {
      return "explanation option-correct-explanation";
    }
    else if(!this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check){
      return "explanation option-wrong-explanation";
    }
    return "explanation"
  }

  optionTextCssClass(id: number): string {
    if(this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check) {
      return "answer check-question-text-correct";
    }
    else if(!this.userAnswer[id].option_is_correct && this.userAnswer[id].user_check){
      return "answer check-question-text-wrong";
    }
    return "answer"
  }
}