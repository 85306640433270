<div class="login-container">
  <nz-card class="card-body">
    <div class="login-wrapper">
      <img class="logo" src="/assets/logo/medexam.png" alt="medexam" />
      <h1>{{'login' | translate}}</h1>
      <form nz-form [formGroup]="loginForm" (ngSubmit)="submitForm()">
        <nz-form-item class="input-container">
          <nz-form-control [nzErrorTip]="emailErrorTpl">
            <nz-form-label nzRequired for="email">{{'email' |translate}}</nz-form-label>
            <nz-input-group nzPrefixIcon="mail">
              <input
                [(ngModel)]="loginModel.email"
                id="email"
                type="email"
                nz-input
                nzRequired
                formControlName="email"
                placeholder="mail@exam.com"
              />
            </nz-input-group>
            <ng-template #emailErrorTpl let-control>
              <ng-container *ngIf="control.hasError('email')">{{'emailValidError' |translate}}</ng-container>
              <ng-container *ngIf="control.hasError('required')">{{'emailRequiredError' |translate}}</ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="input-container">
          <nz-form-control nzErrorTip="{{'passwordInputError' |translate}}">
            <nz-form-label nzRequired for="password">{{'password' |translate}}</nz-form-label>
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input
                [(ngModel)]="loginModel.password"
                id="password"
                [type]="passwordVisible ? 'text' : 'password'"
                nz-input
                formControlName="password"
                placeholder="Password"
              />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i
                nz-icon
                [nzType]="!passwordVisible ? 'eye-invisible' : 'eye'"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div class="error-message" *ngIf="loginErrorMessage">
          {{ loginErrorMessage }}
        </div>
        <button class="login-button" nz-button nzType="primary" nzBlock>
          {{'login' |translate}}
        </button>
        <div class="forgot-password-button">
          <a class="forgot-password" (click)="resetPassword()">{{'forgotPassword' |translate}}</a>
        </div>
      </form>
    </div>
  </nz-card>
</div>
