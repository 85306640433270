export class DashboardModel {
    // tslint:disable-next-line:variable-name
    id: number;
    name: string;
    total_question: number;
    blank_question_count: number;
    wrong_question_count: number;
    correct_question_count: number;

  
    constructor() {
      this.id = 0;
      this.name = '';
      this.total_question = 0;
      this.blank_question_count = 0;
      this.wrong_question_count = 0;
      this.correct_question_count = 0;
    }
}