import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/service';
import { apiUrl } from '../../shared/services/api-url';
import { ProfileModel, UserModel } from './profile.model';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  profileModel: ProfileModel[] = []
  userSpeciality: ProfileModel;
  userModel: UserModel;

  constructor(private authService: AuthService, private router: Router) { 
    this.userSpeciality = new ProfileModel;
    this.userModel = new UserModel;
  }

  async ngOnInit(): Promise<void> {

    await this.authService.ServerGet(apiUrl.user).toPromise().then(((res:any) => {
      localStorage.setItem('language', res.language);
      this.getSpecialities();
    }))

  }

  getSpecialities() : void {
    let lang = localStorage.getItem('language')
    this.authService.ServerGet(apiUrl.specialities+lang+"/").subscribe((res : any) => {
        this.profileModel = res;
        this.getUserSpeciality();
      }
    );
  }

  getUserSpeciality(): void {
    let speciality = sessionStorage.getItem('speciality');

    if(speciality){
      var id = parseInt(speciality);
      var chooseSpeciality = this.profileModel.filter(specialityName => specialityName.id == id)
      this.userSpeciality= chooseSpeciality[0];
    }
    else {
      this.userSpeciality = this.profileModel[0];
    }

    this.userSpeciality.is_selected_speciality = true;
    this.userModel.language = localStorage.getItem('language');
    this.userModel.speciality = this.userSpeciality.id;
  }

  changeSpeciality(speciality: ProfileModel): void{
    this.userSpeciality = new ProfileModel;
    this.userSpeciality = speciality;
    this.userModel.speciality = this.userSpeciality.id;
  }

  changeLanguage(languageCode: string): void {
    this.userModel.language = languageCode;
  }

  saveProfile(): void {
    this.authService.ServerPut(apiUrl.user, this.userModel).subscribe((res: any) => {
        localStorage.setItem('language', res.language);
        this.router.navigate(['/capitals']);
      }
    );
  }
}
