import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CapitalsComponent } from './components/capitals/capitals.component';
import { QuestionListComponent } from './components/capitals/question-list/question-list.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { ProfileComponent } from './components/profile/profile.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { QuestionDetailComponent } from './components/capitals/question-detail/question-detail.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from './shared/guard/account/auth.guard';
import { ProfileGuard } from './shared/guard/account/profile.guard';
import { ComponentGuard } from './shared/guard/account/component.guard';
import { ChangeLanguageComponent } from './shared/components/change-language/change-language.component';

const routes: Routes = [
  { path: '',  component: HeaderComponent, canActivate: [AuthGuard],
    children: [
      { path: '',   redirectTo: '/capitals', pathMatch: 'full' }, // redirect to `login-component`
      { path: 'capitals', component: CapitalsComponent, canActivate: [ComponentGuard]},
      { path: 'questions', component: QuestionListComponent, canActivate: [ComponentGuard]},
      { path: 'question/:id', component: QuestionDetailComponent, canActivate: [ComponentGuard]},
      { path: 'profile', component: ProfileComponent, canActivate: [ProfileGuard]},
      { path: 'dashboard', component: DashboardComponent, canActivate: [ComponentGuard]},
    ]
  },
  { path: '',  component: ChangeLanguageComponent,
    children: [
      {path: 'login', component: LoginComponent},
      { path: 'reset-password', component: ForgotPasswordComponent},
    ]
  },
  { path: '**', redirectTo: '/capitals', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
