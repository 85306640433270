<app-spinner *ngIf="isSpinner" [isSpinning]="isSpinner"></app-spinner>
<div class="breadcrumb-container" *ngIf="specialityName && !isSpinner">
  <nz-breadcrumb>
    <nz-breadcrumb-item>       
      {{'questions' |translate |titlecase}}    
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a [routerLink]="['/capitals']">{{ specialityName }}</a>
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <a *ngIf="!isSpinner"
        [routerLink]="['/questions']"
        [queryParams]="{ capital: capital.id }"
        >{{capital.index}}.&nbsp;{{ capital.name }}</a
      >
    </nz-breadcrumb-item>
    <nz-breadcrumb-item>
      <span>{{'question' |translate}} {{questionIndex}}</span>
    </nz-breadcrumb-item>
  </nz-breadcrumb>
</div>

<div class="question-container" *ngIf="!isSpinner">
  <nz-card class="card-body">
    <div class="question-wrapper" *ngIf="question.question_text && !isSpinner">
      <div class="answer-wrapper">
        <div class="question-image-wrapper" *ngIf="question.image">
          <img src="{{question.image}}" alt="question-image">
        </div>
        <div class="question-text">
          <span class="question-id">{{questionIndex}}.</span>
          <span *ngIf="question.question_text">{{question.question_text}}</span>
        </div>
        <nz-list
          nzSize="small"
          *ngFor="let option of question.option; let i = index"
        >
          <nz-list-item>
            <div *ngIf="option" (click)="checkOption(i)" [ngClass]="checkAnswer ? optionTextCssClass(i) : 'answer'">
              <div [ngClass]="optionCssClass(i)">
                {{ options[i] }}
              </div>
              <span>{{ option }}</span>
            </div>
            <div
              *ngIf="checkAnswer && userAnswer[i].option_hint"
              [ngClass]="explanationCssClass(i)"
            >
              <span>{{ userAnswer[i].option_hint }}</span>
            </div>
          </nz-list-item>
        </nz-list>

        <div class="question-source" *ngIf="checkAnswer && (question.question_source_link || question.question_source_name)">
          <div>
            <span>
              {{'source' |translate }} 
            </span>
          </div>
          <div class="source">
            <a *ngIf="question.question_source_link" href="{{question.question_source_link}}" target="_blank">
              {{ question.question_source_name ? question.question_source_name : question.question_source_link}}
             </a>
            <span *ngIf="!question.question_source_link">{{ question.question_source_name }}</span>
          </div>
        </div>
      </div>
      <div class="button-wrapper">
        <div *ngIf="!question.previous_question"></div>
        <button *ngIf="question.previous_question"  (click)="prevQuestion()" nzSize="large" [ngClass]="'outline-button'"  class="prev-button"
                nz-button nzGhost>
          <i nz-icon nzType="left"></i>{{'previous' |translate}}
        </button>
        <button
          nzSize="large"
          [ngClass]="
            checkAnswer ? 'check-answer-button-disabled' : 'check-answer-button'
          "
          nz-button
          [disabled]="checkAnswer"
          (click)="sendAnswer()"
        >
          {{'checkAnswer' |translate}}
        </button>
        <button *ngIf="question.next_question" (click)="nextQuestion()" nzSize="large" [ngClass]="'outline-button'" 
                nz-button nzGhost>
          {{'next' |translate}}<i nz-icon nzType="right"></i>
        </button>
        <button (click)="sendQuestionAnswer()" *ngIf="!question.next_question" (click)="goDashboard()" nzSize="large" [ngClass]="'outline-button'" 
                nz-button nzGhost>
          {{'finish' |translate}}<i nz-icon nzType="right"></i>
        </button>
      </div>
      <div class="button-wrapper-mobile">
        <div class="prev-next-wrapper">
          <button
            (click)="prevQuestion()"
            nzSize="large"
            [ngClass]="{ visibility_hidden: !question.previous_question }"
            class="outline-button prev-button"
            nz-button
            nzGhost
          >
            <i nz-icon nzType="left"></i>{{'previous' |translate}}
          </button>
          <button
            *ngIf="question.next_question"
            (click)="nextQuestion()"
            nzSize="large"
            [ngClass]="'outline-button'"
            nz-button
            nzGhost
          >
          {{'next' |translate}}<i nz-icon nzType="right"></i>
          </button>
          <button
            (click)="sendQuestionAnswer()"
            *ngIf="!question.next_question"
            (click)="goDashboard()"
            nzSize="large"
            [ngClass]="'outline-button'"
            nz-button
            nzGhost
          >
          {{'finish' |translate}}<i nz-icon nzType="right"></i>
          </button>
        </div>
        <div class="check-answer-wrapper">
          <button
            nzSize="large"
            [ngClass]="
              checkAnswer
                ? 'check-answer-button-disabled'
                : 'check-answer-button'
            "
            nz-button
            [disabled]="checkAnswer"
            (click)="sendAnswer()"
          >
          {{'checkAnswer' |translate}}
          </button>
        </div>
      </div>
    </div>
  </nz-card>
</div>

